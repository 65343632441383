import {Component} from '@angular/core';
import {environment} from '@env/environment';
import {BackEndVersionService} from '@app/core/services/back-end-version.service';
import { appConfig } from '@app/core';

@Component({
  selector: 'app-version-numbers',
  templateUrl: './version-numbers.component.html',
  styleUrls: ['./version-numbers.component.scss']
})
export class VersionNumbersComponent {
  environment = environment;
  appConfig = appConfig;

  constructor(
    public backEndVersionService: BackEndVersionService,
  ) {
  }
}
