import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpErrorResponse, HttpClient
} from '@angular/common/http';
import {Observable, TimeoutError} from 'rxjs';
import {tap, timeout} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {UserService} from '../services/user.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ApiErrorDialogComponent} from '../../shared/components/dialogs/api-error-dialog/api-error-dialog.component';
import {Router} from "@angular/router";
import {appConfig} from "@app/core";
import {BackEndVersionService} from '@app/core/services/back-end-version.service';
import * as moment from 'moment/moment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private dialogRef: MatDialogRef<ApiErrorDialogComponent, any>;
  private transFormedHeaders = {};
  private tryApiServerPingPending = false;

  constructor(
    private backEndVersionService: BackEndVersionService,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private httpClient: HttpClient,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const transformedRequest = this.getTransformedRequest(request);
    const isPingUrl = this.getIsPingUrl(request.url);
    return next.handle(transformedRequest).pipe(
      timeout(this.getTimeOut(request)),
      tap((response) => {
          if (response instanceof HttpResponse) {
            this.handleHttpResponse(response);
          }
        },
        (response: HttpErrorResponse) => {
          this.handleErrorResponse(response, isPingUrl);
        }
      )
    );
  }

  private getTimeOut(request: HttpRequest<any>): number {
    if (this.getIsPingUrl(request.url)) {
      return 5000;
    } else {
      return environment.apiServerResponseTimeoutMs;
    }
  }

  private getTransformedRequest(request: HttpRequest<any>) {
    if (this.getIsThirdPartyApi(request.url)) return request;

    this.transFormedHeaders = {};
    this.transFormedHeaders['Accept'] = 'application/json';

    const apiToken = this.userService.getUser()?.apiToken;
    if (apiToken) this.transFormedHeaders['apitoken'] = apiToken;

    this.transFormedHeaders['version'] = environment.version;

    return request.clone({
      url: this.getTransformedUrl(request.url),
      setHeaders: this.transFormedHeaders
    });
  }

  private getIsThirdPartyApi(url: string): boolean {
    if (!url) return false;
    return url.startsWith('https://dev.virtualearth.net');
  }

  private getTransformedUrl(url: string): string {
    if (url.split('/assets/version.json').length > 1) return url;

    if (environment.useProxy) {
      return this.getTransformedQueryParamUrl(`proxyApi/${environment.apiPrefix}/${url}`);
    } else {
      return this.getTransformedQueryParamUrl(`${environment.apiBaseUrl}/${environment.apiPrefix}/${url}`);
    }
  }

  private getTransformedQueryParamUrl(url: string): string {
    const urlSplit = url.split('?');
    if (urlSplit.length === 1) {
      return `${url}/`
    } else {
      return url
    }
  }

  private handleHttpResponse(response: HttpResponse<any>) {
    this.setApiTokenExpire(response);
    this.setBackEndVersion(response);
    this.setBackEndDate(response);
  }

  private setApiTokenExpire(response: HttpResponse<any>) {
    const apiTokenExpireDate = response.headers.get('api-token-expire-date');
    if (apiTokenExpireDate) this.userService.setApiTokenExpireDate(apiTokenExpireDate);
  }

  private setBackEndVersion(response: HttpResponse<any>) {
    const backendVersion = response.headers.get('back-end-version');
    if (backendVersion) this.backEndVersionService.backEndVersion = backendVersion;
  }

  private setBackEndDate(response: HttpResponse<any>) {
    const backendDate = response.headers.get('back-end-version-date');
    if (backendDate) this.backEndVersionService.backEndDate = moment(backendDate).toDate();
  }

  private handleErrorResponse(response: HttpErrorResponse | TimeoutError, isPingUrl = false) {
    if (response instanceof TimeoutError) {
      if (!isPingUrl) {
        this.tryApiServerPing(response);
      }
    }
    if (response instanceof HttpErrorResponse) {
      if (response.url.split('/assets/version.json').length > 1) {
        return;
      }
      this.handleHttpErrorResponse(response);
    }
  }

  private handleHttpErrorResponse(response: HttpErrorResponse) {
    if (this.getIsThirdPartyApi(response.url)) return;
    if (response.url && response.status === 401) {
      this.handleUnauthorizedUser();
    } else if (response.status === 404 || response.status === 500) {
      this.handleApiServerPossiblyOffline(response);
    } else {
      this.showApiErrorDialog(response);
    }
  }

  private handleUnauthorizedUser() {
    this.userService.unSetUser();
    this.router.navigateByUrl('/auth/login');
  }

  private handleApiServerPossiblyOffline(response: HttpErrorResponse) {
    if (!response.url) return;
    if (!this.getIsPingUrl(response.url)) {
      this.tryApiServerPing(response);
    }
  }

  private getIsPingUrl(url: string): boolean {
    let split = url.split('/');
    split = split.filter(splitPart => splitPart !== '');
    if (split.length !== 2) return false;
    return !(split[0] !== 'public' && split[1] !== 'ping');
  }

  private tryApiServerPing(response: HttpErrorResponse | TimeoutError) {
    if (this.tryApiServerPingPending) return;
    this.tryApiServerPingPending = true;
    this.httpClient.get('public/ping').subscribe(
      () => {
        this.tryApiServerPingPending = false;
        this.showApiErrorDialog(response);
      },
      () => {
        if (environment.redirectToApiOffLineAfterApiTimeOut) {
          this.tryApiServerPingPending = false;
          localStorage.setItem(appConfig.localStorageKeys.urlToOpenAfterApiBackOnline, location.href)
          this.router.navigateByUrl('/api-off-line');
        }
      });
  }

  private showApiErrorDialog(response: HttpErrorResponse | TimeoutError) {
    this.dialogRef?.close();
    if (this.router.routerState.snapshot.url === 'api-off-line') return;
    this.dialogRef = this.dialog.open(ApiErrorDialogComponent, {data: response});
  }
}
