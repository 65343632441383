import {Injectable} from '@angular/core';
import {User} from '../models/user.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {appConfig} from "../app-config";
import *  as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _user: User;
  private user: BehaviorSubject<User>;

  user$: Observable<User>;

  constructor() {
    this._user = null;
    this.user = new BehaviorSubject<User>(null);
    this.user$ = this.user.asObservable();
  }

  private triggerUpdate() {
    this.user.next(Object.assign(new User(), this._user));
  }

  setUser(user: User) {
    user.id = `${user.id}`;
    this._user = Object.assign(new User(), user);
    this._user.setRights();
    this.updateUser();
  }

  private updateUser() {
    localStorage.setItem(appConfig.localStorageKeys.user, CryptoJS.AES.encrypt(JSON.stringify(this._user), appConfig.ba9123jj12klasd).toString());
    this.triggerUpdate();
  }

  updateLocalUser(user: User) {
    if(!this._user) return;
    this._user.name = user.name;
    this._user.city = user.city;
    this._user.street = user.street;
    this._user.canDownloadSubmittedWorkOrderPDF = user.canDownloadSubmittedWorkOrderPDF;
    this._user.canBeAssignedToWorkOrder = user.canBeAssignedToWorkOrder;
    this._user.canEnterWorkOrderPeriodAsBreakType = user.canEnterWorkOrderPeriodAsBreakType;
    this._user.canMangeSafetyIncidentStatus = user.canMangeSafetyIncidentStatus;
    this._user.setRights();
    this.updateUser();
  }

  unSetUser() {
    this._user = null;
    localStorage.removeItem(appConfig.localStorageKeys.user);
    this.triggerUpdate();
  }

  getUser(): User {
    return this._user;
  }

  setApiTokenExpireDate(apiTokenExpireDate) {
    if (!this._user) return;
    this._user.id = `${this._user.id}`;
    this._user.apiTokenExpireDate = apiTokenExpireDate;
    this.updateUser();
  }
}
