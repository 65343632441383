import {ExperiTableFilters} from "@app/shared/components/experi-table/experi-table-filters.model";

export class FiltersTicketsTable extends ExperiTableFilters {
  query?: string;
  workTypeIds?: string[];
  ticketStateEnums?: number[];
  prio?: 1 | 2 | 3;
  erroneousTickets?: boolean;
  readyToCompleteTickets?: boolean;
  projectQuery?: string;
  startWorkDateFromDate?: Date;

  useDurationArrival?: boolean;
  durationArrivalMin?: number;
  durationArrivalMax?: number;

  useDurationFunctionalRepair?: boolean;
  durationFunctionalRepairMin?: number;
  durationFunctionalRepairMax?: number;

  useDurationPermanentRepair?: boolean;
  durationPermanentRepairMin?: number;
  durationPermanentRepairMax?: number;

  useDurationBlockageRoad?: boolean;
  durationBlockageRoadMin?: number;
  durationBlockageRoadMax?: number;

  useDurationBlockageWater?: boolean;
  durationBlockageWaterMin?: number;
  durationBlockageWaterMax?: number;

  constructor() {
    super();
  }

  getTrimmedFilters(): FiltersTicketsTable {
    const trimmedFilters: FiltersTicketsTable = Object.assign(new FiltersTicketsTable(), {});

    if (this.query && this.query !== '') {
      trimmedFilters['query'] = this.query;
    }
    if (this.workTypeIds && this.workTypeIds.length > 0) {
      trimmedFilters['workTypeIds'] = this.workTypeIds;
    }
    if (this.prio) {
      trimmedFilters['prio'] = this.prio;
    }
    if (this.ticketStateEnums && this.ticketStateEnums.length > 0) {
      trimmedFilters['ticketStateEnums'] = this.ticketStateEnums;
    }
    if (this.erroneousTickets || this.erroneousTickets === false) {
      trimmedFilters['erroneousTickets'] = this.erroneousTickets;
    }
    if (this.readyToCompleteTickets || this.readyToCompleteTickets === false) {
      trimmedFilters['readyToCompleteTickets'] = this.readyToCompleteTickets;
    }
    if (this.projectQuery && this.projectQuery !== '') {
      trimmedFilters['projectQuery'] = this.projectQuery;
    }
    if (this.startWorkDateFromDate) {
      trimmedFilters['startWorkDateFromDate'] = this.startWorkDateFromDate;
    }

    if (this.useDurationArrival) {
      if (this.durationArrivalMin) trimmedFilters['durationArrivalMin'] = this.durationArrivalMin;
      if (this.durationArrivalMax) trimmedFilters['durationArrivalMax'] = this.durationArrivalMax;
    }

    if (this.useDurationFunctionalRepair) {
      if (this.durationFunctionalRepairMin) trimmedFilters['durationFunctionalRepairMin'] = this.durationFunctionalRepairMin;
      if (this.durationFunctionalRepairMax) trimmedFilters['durationFunctionalRepairMax'] = this.durationFunctionalRepairMax;
    }

    if (this.useDurationPermanentRepair) {
      if (this.durationPermanentRepairMin) trimmedFilters['durationPermanentRepairMin'] = this.durationPermanentRepairMin;
      if (this.durationPermanentRepairMax) trimmedFilters['durationPermanentRepairMax'] = this.durationPermanentRepairMax;
    }

    if (this.useDurationBlockageRoad) {
      if (this.durationBlockageRoadMin) trimmedFilters['durationBlockageRoadMin'] = this.durationBlockageRoadMin;
      if (this.durationBlockageRoadMax) trimmedFilters['durationBlockageRoadMax'] = this.durationBlockageRoadMax;
    }

    if (this.useDurationBlockageWater) {
      if (this.durationBlockageWaterMin) trimmedFilters['durationBlockageWaterMin'] = this.durationBlockageWaterMin;
      if (this.durationBlockageWaterMax) trimmedFilters['durationBlockageWaterMax'] = this.durationBlockageWaterMax;
    }

    if (Object.keys(trimmedFilters).length > 0) {
      return trimmedFilters;
    } else {
      return null;
    }
  }
}
