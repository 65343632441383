// Angular
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// Modules
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {AngularPageVisibilityModule} from "angular-page-visibility";
import {MatMomentDatetimeModule } from '@ng-matero/extensions-moment-adapter';

// AppModule
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

// Components
import { VersionCheckerComponent } from './version-checker.component';


@NgModule({
  declarations: [
    AppComponent,
    VersionCheckerComponent
  ],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Modules
    CoreModule,
    SharedModule,
    AngularPageVisibilityModule,
    MatMomentDatetimeModule,

    // App
    AppRoutingModule,
     // ServiceWorkerModule.register('ngsw-worker.js', {
     //   enabled: environment.isProduction,
     //   // Register the ServiceWorker as soon as the app is stable
     //   // or after 30 seconds (whichever comes first).
     //   registrationStrategy: 'registerWhenStable:30000'
     // }),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
