<mat-dialog-content>

  <div class="app-dialog-header">
    <h2 class="title">Actie niet voltooid</h2>
  </div>

  <pre class="error-message">
    <ng-container *ngIf="url">
      {{url}}<br/><br/>
    </ng-container>
    <ng-container *ngIf="message">
      {{message}}<br/><br/>
    </ng-container>
    <ng-container *ngIf="error">
      {{error}}
    </ng-container>
  </pre>
</mat-dialog-content>

<mat-dialog-actions>
  <button *ngIf="isTimeOutError"
          mat-flat-button color="primary"
          (click)="onReloadClick()">
    <mat-icon>cached</mat-icon>
    <span>Herlaad pagina</span>
  </button>

  <button mat-raised-button
          color="accent"
          (click)="close()">Sluiten</button>
</mat-dialog-actions>
