import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

// Guards
import {UserGuard} from './core/guards/user.guard';
import {NoUserGuard} from "./core/guards/no-user.guard";

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [NoUserGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'dashboard',
    canActivate: [UserGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'sensor-status',
    loadChildren: () => import('./sensor-status/sensor-status.module').then(m => m.SensorStatusModule)
  },
  {
    path: 'api-off-line',
    loadChildren: () => import('./api-off-line/api-off-line.module').then(m => m.ApiOffLineModule)
  },
  {
    path: '**',
    redirectTo: 'auth'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
