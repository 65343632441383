export class WorkType {
  label: string;
  id: string;
  backgroundColor: string;
  textColor: string;
  abbreviation: string;
  enum: number;
}

export enum WorkTypeEnum {
  Failure = 10,
  Assembly = 20,
  Inspection = 30,
  BridgeTurn = 41,
  Maintenance = 40,
  MiscEPC = 50,
}
