import {ProjectStructureBase} from "./project-structure-base.model";

export class ProjectItem extends ProjectStructureBase {
  _atLeastOneHourFilledIn: boolean;
  disabled: boolean;

  isGeneratedByDefault: boolean;
  readOnly: boolean;
  exactId?: string;
  sortOrder?: number;
  projectItemType?: number;

  hoursMechanicOutdoor?: number;
  hoursMechanicIndoor?: number;
  hoursPerformer?: number;
  hoursProjectLeader?: number;
  hoursEngineer?: number;
  hoursSoftwareEngineer?: number;

  pricePerUnit?: number;
  amountOfUnits?: number;
  totalPrice?: number;
}

export enum ProjectItemEnum {
  Uur,
  Prijs,
}
