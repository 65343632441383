import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormGroup, FormArray, FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {environment} from "@env/environment";

@Component({
  selector: 'app-form-debugger',
  templateUrl: './form-debugger.component.html',
  styleUrls: ['./form-debugger.component.scss']
})
export class FormDebuggerComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;

  environment = environment;
  form$: Subscription;
  status$: Subscription;
  invalidFields: { name: string, control: AbstractControl }[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    if (this.environment.isProduction) return;

    this.form$ = this.form.valueChanges.subscribe(() => {
      this.setInvalidFields();
    });

    this.status$ = this.form.statusChanges.subscribe(() => {
      this.setInvalidFields();
    });

    this.setInvalidFields();
  }

  private setInvalidFields() {
    const invalidFields: { name: string, control: AbstractControl }[] = [];
    this.getInvalidFieldsFromFormGroup(invalidFields, this.form.controls);
    this.changeDetectorRef.markForCheck();
    this.invalidFields = [...invalidFields];
    if (this.invalidFields.length) console.warn(this.invalidFields);
    this.changeDetectorRef.detectChanges();
  }

  private getInvalidFieldsFromFormGroup(
    invalidFields: { name: string, control: AbstractControl }[],
    formGroup: {},
    prefix = ''
  ) {
    const controls = formGroup;
    for (const name in controls) {
      if (controls[name].invalid) {
        if (controls[name] instanceof UntypedFormArray) {
          controls[name].controls.forEach((formGroup, i) => {
            if (formGroup.controls) {
              this.getInvalidFieldsFromFormGroup(invalidFields, formGroup.controls, `${prefix}${name}_${i}_`);
            } else {
              invalidFields.push({name: `${prefix}${name}`, control: formGroup[name]});
            }
          });
        } else {
          invalidFields.push({name: `${prefix}${name}`, control: formGroup[name]});
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.form$?.unsubscribe()
    this.status$?.unsubscribe()
  }

}
