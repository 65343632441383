import {SortDirection} from '@angular/material/sort';

export class FilterSortPaginate {
  filters?: {};
  sortBy = '';
  sortDirection: 'asc' | 'desc' | SortDirection = 'asc';
  limit = 25;
  page = 0;

  getMatSorting?() {
    return {
      id: this.sortBy,
      start: this.sortDirection
    }
  }

  getParams?(): { [header: string]: string | string[]; } {
    const params = {
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
      limit: this.limit ? this.limit.toString() : '0',
      page: this.page ? this.page.toString() : '1',
    };

    if (!this.filters || Object.keys(this.filters).length === 0) {
      return params;
    }
    for (const filterKey in this.filters) {
      params[`filter_${filterKey}`] = this.filters[filterKey];
    }
    return params;
  }
}
