import {ExperiTableFilters} from "@app/shared/components/experi-table/experi-table-filters.model";

export class FiltersProjectsTable extends ExperiTableFilters {
  query?: string;
  projectManagerIds?: string[];
  isActive?: boolean;

  getTrimmedFilters(): FiltersProjectsTable {
    const trimmedFilters: FiltersProjectsTable = Object.assign(new FiltersProjectsTable(), {});

    if (this.query && this.query !== '') {
      trimmedFilters['query'] = this.query;
    }

    if (this.projectManagerIds && this.projectManagerIds.length > 0) {
      trimmedFilters['projectManagerIds'] = this.projectManagerIds;
    }

    if (this.isActive || this.isActive === false) {
      trimmedFilters['isActive'] = this.isActive;
    }

    if (Object.keys(trimmedFilters).length > 0) {
      return trimmedFilters;
    } else {
      return null;
    }
  }
}
