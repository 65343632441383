export class CalendarNoteType {
  _backgroundColorLightened?: string;
  id: string;
  label: string;
  enum: number;
  backgroundColor: string;
  isVisibleInMechanicCalendar: boolean;
  isVisibleInLocationCalendar: boolean;
  isVisibleInBreakDownServiceCalendar: boolean;
  isVisibleInReservationCalendar: boolean;
  textColor: string;
  requiresTitle: boolean;
}

export enum CalendarNoteTypeEnum {
  Verlof = 1,
  Cursus,
  Ziek,
  Gereserveerd,
  Storingsdienst,
  Reservering,
}
