import {Pipe, PipeTransform} from '@angular/core';
import {WorkType} from '../../../core/models/work-type.model';

@Pipe({
  name: 'workTypeById'
})
export class WorkTypeByIdPipe implements PipeTransform {

  transform(allWorkTypes: WorkType[], id: string): WorkType {
    if (!allWorkTypes) {
      return null;
    }

    return allWorkTypes.find(workType => workType.id === id);
  }

}
