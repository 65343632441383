export class WorkOrderPeriodHourStatus {
  id: string;
  label: string;
  enum: number;
}

export enum WorkOrderPeriodHourStatusEnum {
  Unavailable = 0,
  Draft = 1,
  Rejected = 2,
  Submitted = 10,
  FailedOnApproval = 11,
  Processing = 14,
  Processing2 = 16,
  FailedWhileUndoingApproval = 19,
  Final = 20
}
