<ng-container *ngIf="!environment.isProduction">
  <ng-container *ngIf="invalidFields.length > 0; else showNoInvalid">
    <ng-container *ngFor="let invalidField of invalidFields">
      <h4>{{invalidField.name}}</h4>
    </ng-container>
  </ng-container>

  <ng-template #showNoInvalid>
    No Invalid Fields
  </ng-template>
</ng-container>
