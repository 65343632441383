import {EventEmitter} from "@angular/core";

export class ExternalFile {
  _deletePending? = false;
  _temporaryId?: number;
  _formValid?: boolean;
  _doSave?: EventEmitter<void>;

  base64?: string;
  id: string;
  title?: string;
  description?: string;
  mimeType: string;
  bytes?: number;
  modifiedDate?: Date;
  userName?: string;
  userId?: string;
  ticketId?: string;
  ticketNumber?: string;
  filePath?: string
  thumbPath?: string
}
