export class FiltersNotifiersTable {
  query?: string;
  isActive?: boolean;

  getTrimmedFilters(): FiltersNotifiersTable {
    const trimmedFilters: FiltersNotifiersTable = Object.assign(new FiltersNotifiersTable(), {});

    if (this.query && this.query !== '') {
      trimmedFilters['query'] = this.query;
    }

    if (Object.keys(trimmedFilters).length > 0) {
      return trimmedFilters;
    } else {
      return null;
    }
  }
}
