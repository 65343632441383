import {CentsToEurosPipe} from "../pipes/cents-to-euros.pipe";
import {EurosToCentsPipe} from "../pipes/euros-to-cents.pipe";

export class MaterialPreset {
  id: string;
  name: string;
  unit?: string;
  description?: string;
  articleNumber?: string;

  _costPriceEuros?: number;
  costPriceEuroCents?: number;

  _salesPriceEuros?: number;
  salesPriceEuroCents?: number;

  convertCentsToEuros?() {
    this._costPriceEuros = CentsToEurosPipe.getEurosFromCents(this.costPriceEuroCents);
    this._salesPriceEuros = CentsToEurosPipe.getEurosFromCents(this.salesPriceEuroCents);
  }

  convertEurosToCents?(deleteEuroVars = true) {
    this.costPriceEuroCents = EurosToCentsPipe.getCentsFromEuros(this._costPriceEuros);
    this.salesPriceEuroCents = EurosToCentsPipe.getCentsFromEuros(this._salesPriceEuros);
    if (deleteEuroVars) {
      delete this._costPriceEuros;
      delete this._salesPriceEuros;
    }
  }
}
