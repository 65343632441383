import {Pipe, PipeTransform} from '@angular/core';
import {isNumeric} from "@app/shared/utils/is-numeric";

@Pipe({
  name: 'eurosToCents'
})
export class EurosToCentsPipe implements PipeTransform {

  transform(cents: number): number {
    return EurosToCentsPipe.getCentsFromEuros(cents);
  }

  static getCentsFromEuros(cents: number): number {
    if (isNumeric(cents)) {
      return Math.round(cents * 100);
    } else {
      console.warn('Not a number', this);
      return 0;
    }
  }
}
