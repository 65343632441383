import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CalendarNoteType} from '@app/core';
import {HttpClient} from '@angular/common/http';
import {LightenOrDarkenColorPipe} from '@app/core/pipes/lighten-or-darken-color.pipe';

@Injectable({providedIn: 'root'})
export class CalendarNoteTypesService {
  private _calendarNoteTypes: BehaviorSubject<CalendarNoteType[]> = null;
  private dataStore: {
    calendarNoteTypes: CalendarNoteType[];
  };
  calendarNoteTypes$: Observable<CalendarNoteType[]> = null;

  constructor(
    private httpClient: HttpClient,
    private lightenOrDarkenColorPipe: LightenOrDarkenColorPipe,
  ) {
    this.dataStore = {calendarNoteTypes: []};
    this._calendarNoteTypes = <BehaviorSubject<CalendarNoteType[]>>new BehaviorSubject(null);
    this.calendarNoteTypes$ = this._calendarNoteTypes.asObservable();
  }

  setItems(response: CalendarNoteType[]) {
    this.dataStore.calendarNoteTypes = response.map((calendarType, i) => {
      calendarType._backgroundColorLightened = this.lightenOrDarkenColorPipe.transform(calendarType.backgroundColor, 50);
      return calendarType;
    });
    this._calendarNoteTypes.next(Object.assign({}, this.dataStore).calendarNoteTypes);
  }

  getCalendarNoteTypeById(id: string) {
    return this.dataStore?.calendarNoteTypes.find(calendarNoteType => calendarNoteType.id === id);
  }

  getCalendarNoteTypeByEnum(enumToFind: number) {
    return this.dataStore?.calendarNoteTypes.find(calendarNoteType => calendarNoteType.enum === enumToFind);
  }

  getCalendarNoteTypes(): CalendarNoteType[] {
    return this.dataStore?.calendarNoteTypes || [];
  }

  clear() {
    this.dataStore.calendarNoteTypes = null;
    this._calendarNoteTypes.next(Object.assign({}, this.dataStore).calendarNoteTypes);
  }
}
