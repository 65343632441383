// Angular
import {NgModule} from '@angular/core';
import {CommonModule, TitleCasePipe, UpperCasePipe,} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {pipes} from './pipes/index';
import {components} from './components/index';

//MAT
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MAT_SELECT_CONFIG, MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatBadgeModule} from '@angular/material/badge';
import {MatRadioModule} from "@angular/material/radio";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {NgxMatFileInputModule} from '@angular-material-components/file-input';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatRippleModule
} from '@angular/material/core';

export const CUSTOM_MOMENT_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YY HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

// Other 3rd Party
import {TruncateCharactersPipe, TruncateModule} from '@yellowspot/ng-truncate';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgResizeObserverPonyfillModule} from "ng-resize-observer";
import {DigitOnlyModule} from '@uiowa/digit-only';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {MtxDatetimepickerModule} from '@ng-matero/extensions/datetimepicker';

// Directives
import {ElementBreakpointDirective} from './directives/element-breakpoint.directive';
import {DatepickerPreventInputDirective} from './directives/datepicker-prevent-input.directive';
import {CustomDateAdapter} from "@app/shared/custom-date-adapter";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import { ButtonIsPendingDirective } from './directives/button-is-pending.directive';
import { NgxOnInitOnDestroyDirective } from './directives/ngx-on-init-on-destroy.directive';
import {MomentModule} from 'ngx-moment';
import {DecimalNumberInputDirective} from '@app/shared/directives/decimal-number-input.directive';
import { IntegerNumberInputDirective } from './directives/integer-number-input.directive';

@NgModule({
  declarations: [
    ...components,
    ...pipes,
    ElementBreakpointDirective,
    DatepickerPreventInputDirective,
    ButtonIsPendingDirective,
    NgxOnInitOnDestroyDirective,
    DecimalNumberInputDirective,
    IntegerNumberInputDirective
  ],
  imports: [
    // Angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // MAT
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatListModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSelectModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatRippleModule,
    MatRadioModule,
    NgxMatFileInputModule,
    MatBottomSheetModule,

    // Other 3rd Party
    TruncateModule,
    NgSelectModule,
    NgResizeObserverPonyfillModule,
    MomentModule,
    DigitOnlyModule,
    MtxDatetimepickerModule
  ],
  exports: [
    ...pipes,
    ...components,
    ElementBreakpointDirective,
    DatepickerPreventInputDirective,
    ButtonIsPendingDirective,
    NgxOnInitOnDestroyDirective,
    DecimalNumberInputDirective,
    IntegerNumberInputDirective,

    // Angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // MAT
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatSidenavModule,
    MatListModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSelectModule,
    MatTooltipModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatRippleModule,
    MatRadioModule,
    MatExpansionModule,
    MatPaginatorModule,
    NgxMatFileInputModule,
    MatBottomSheetModule,

    // Other 3rd Party
    TruncateModule,
    NgSelectModule,
    NgResizeObserverPonyfillModule,
    DigitOnlyModule,
    PdfViewerModule,
    MtxDatetimepickerModule,
    MomentModule
  ],
  providers: [
    ...pipes,
    TruncateCharactersPipe,
    TitleCasePipe,
    UpperCasePipe,
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {hideSingleSelectionIndicator: true}
    },
    {provide: MAT_DATE_LOCALE, useValue: 'nl-NL'},
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS},
    {
      provide: MatPaginatorIntl,
      useValue: Object.assign(new MatPaginatorIntl(), {
        itemsPerPageLabel: 'Per pagina',
        nextPageLabel: 'Volgende',
        previousPageLabel: 'Vorige',
        firstPageLabel: 'Eerste',
        lastPageLabel: 'Laatste',
        getRangeLabel: (page: number, pageSize: number, length: number) => {
          if (length === 0 || pageSize === 0) {
            return `0 van ${length}`;
          }
          length = Math.max(length, 0);
          const startIndex = page * pageSize;
          const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
          return `${startIndex + 1}–${endIndex}/${length}`;
        }
      })
    }
  ]
})
export class SharedModule {
}
