export class CompanyType {
  label: string;
  id: string;
  backgroundColor: string;
  color: string;
  abbreviation: string;
  enum: number;
}

export enum CompanyTypeEnum {
  Infra = 10,
  Shipping = 20,
  Industry = 30,
}
