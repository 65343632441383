import {ExperiTableFilters} from "@app/shared/components/experi-table/experi-table-filters.model";

export class FiltersProjectSectionsTemplatesTable extends ExperiTableFilters {
  query?: string;

  getTrimmedFilters(): FiltersProjectSectionsTemplatesTable {
    const trimmedFilters: FiltersProjectSectionsTemplatesTable = Object.assign(new FiltersProjectSectionsTemplatesTable(), {});

    if (this.query && this.query !== '') {
      trimmedFilters['query'] = this.query;
    }

    if (Object.keys(trimmedFilters).length > 0) {
      return trimmedFilters;
    } else {
      return null;
    }
  }
}
