export class FiltersInstallationPartGroupsTable {
  query?: string;

  getTrimmedFilters(): FiltersInstallationPartGroupsTable {
    const trimmedFilters: FiltersInstallationPartGroupsTable = Object.assign(new FiltersInstallationPartGroupsTable(), {});

    if (this.query && this.query !== '') {
      trimmedFilters['query'] = this.query;
    }

    if (Object.keys(trimmedFilters).length > 0) {
      return trimmedFilters;
    } else {
      return null;
    }
  }
}
