import {Pipe, PipeTransform} from '@angular/core';
import {CalendarNoteType} from '@app/core';
import {CalendarNoteTypesService} from '@app/core/services/calendar-note-types.service';

@Pipe({
  name: 'calendarTypeById'
})
export class CalendarNoteTypeByIdPipe implements PipeTransform {

  constructor(
    private calendarNoteTypesService: CalendarNoteTypesService
  ) {
  }

  transform(id: string): CalendarNoteType {
    return this.calendarNoteTypesService.getCalendarNoteTypeById(id);
  }

}
