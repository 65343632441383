import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {appConfig, FilterSortPaginate} from '@app/core';
import {ExperiTableFilters} from '@app/shared/components/experi-table/experi-table-filters.model';
import {MatSort, MatSortable} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {BehaviorSubject, merge, of, Subscription} from 'rxjs';
import {ExperiTableApiService} from '@app/shared/components/experi-table/experi-table-api.service';
import {MatTableDataSource} from '@angular/material/table';
import {catchError, map, skip, switchMap} from 'rxjs/operators';
import {ExperiTableWsService} from '@app/shared/components/experi-table/experi-table-ws.service';
import {SelectionModel} from '@angular/cdk/collections';
import {WebSocketService} from "@app/core/web-socket.service";

@Component({
  selector: 'app-experi-table',
  template: '',
})
export class ExperiTableComponent<T> implements OnInit, OnDestroy {
  @Input() inputData?: T[];
  @Input() inputApiService?: ExperiTableApiService<T>;
  @Input() inputWSService?: ExperiTableWsService<T>;
  @Input() customWebSocketService?: WebSocketService;

  @Input() filterSortPaginatePostUrl?: string;
  @Input() filterSortPaginateKeyForItems?: string;
  @Input() localStoragePaginateKey?: string;
  @Input() localStorageSortKey?: string;
  @Input() localStorageFilterKey?: string;

  @Input() canCustomSelectColumns = false;
  @Input() allColumns?: string[];
  @Input() canAdd = true;
  @Input() canSearch = true;
  @Input() canSort = true;
  @Input() canFilter = true;
  @Input() canSelect = false;
  @Input() fullPage = true;
  @Input() addBorder = false;
  @Input() customDefaultSort?: MatSortable;
  @Input() defaultFiltering?: ExperiTableFilters;
  @Input() narrowRows = false;
  @Input() pageSizeOptions: number[] = appConfig.pageSizeOptions;
  @Input() stickyStartColumn?: string;
  @Input() useRawDataFilter = false;
  @Input() useRawDataMapper = false;

  @Output() totalCountUpdated = new EventEmitter<number>();

  private service$?: Subscription;
  private sort$?: Subscription;
  private paginate$?: Subscription;
  private triggerWSUpdateInputs$?: Subscription;
  private selection$?: Subscription;
  private inputWSService$?: Subscription;

  appConfig = appConfig;
  defaultSort?: MatSortable;
  displayedColumns?: string[];
  triggerFilterSortPaginate$: EventEmitter<void> = new EventEmitter<void>();

  elBreakpoint: number | undefined;
  loadedFromService = false;
  loadedFromServiceError = false;
  paginationTotalCount = 0;
  dataSource: MatTableDataSource<T> = new MatTableDataSource<T>();
  dataSourceSet = false;
  firstSorting?: MatSortable;
  formValues: BehaviorSubject<ExperiTableFilters> = new BehaviorSubject<ExperiTableFilters>(new ExperiTableFilters());
  selection = new SelectionModel<T>(true, []);
  selectModeActive = false;
  columnToSwapForSelectColumn?: string;
  isAllSelected = false;

  @ViewChild(MatSort, {static: false}) sort?: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator?: MatPaginator;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private webSocketService: WebSocketService,
  ) {
  }

  ngOnInit(): void {
    if (!this.inputData && !this.inputApiService && !this.inputWSService) {
      throw new Error('Set at least 1 input method');
    }
    if (
      (this.inputData && (this.inputApiService || this.inputWSService)) ||
      (this.inputApiService && (this.inputData || this.inputWSService)) ||
      (this.inputWSService && (this.inputData || this.inputApiService))
    ) {
      throw new Error('More than 1 input method provided');
    }
    this.onInitStart();
    this.setFirstSorting();
    if (!this.canCustomSelectColumns) {
      this.setDisplayColumns(this.allColumns);
    }
    if (this.inputWSService) {
      this.subscribeToWebSocketItems();
    }
    this.onInitEnd();
    this.subscribeToSelection();
  }

  onInitStart(): void {
    // keep empty; to be called by extended class
  }

  onInitEnd(): void {
    // keep empty; to be called by extended class
  }

  private subscribeToWebSocketItems(): void {
    this.inputWSService$ = this.inputWSService?.items$?.subscribe(items => {
      if (!this.filterSortPaginateKeyForItems) {
        throw new Error(`No filterSortPaginateKeyForItems passed: ${this.constructor.name}`);
      }
      if (!items) return;
      this.loadedFromService = true;
      // @ts-ignore
      if (items && items[this.filterSortPaginateKeyForItems]) {
        this.paginationTotalCount = items.totalCount;
        // @ts-ignore
        this.dataSource.data = this.getFilteredAndMappedRawData(items[this.filterSortPaginateKeyForItems] as T[]);
      } else {
        this.paginationTotalCount = 0;
        this.dataSource.data = [];
      }
      this.totalCountUpdated.emit(this.paginationTotalCount);
    });
  }

  private getFilteredAndMappedRawData(rawData: T[]): T[] {
    if ((!this.filterRawData || !this.useRawDataFilter) && (!this.mapRawData || !this.useRawDataMapper)) {
      return rawData;
    } else {
      if(this.filterRawData && this.useRawDataFilter) {
        rawData = this.filterRawData(rawData);
      }
      if (this.mapRawData && this.useRawDataMapper) {
        rawData = rawData.map((rawDataEntry: T, index: number, array: T[]) => {
          return this.mapRawData(rawDataEntry, index, array);
        });
      }
      return rawData
    }
  }

  filterRawData(rawData: T[]): T[] {
    // keep empty; to be called by extended class
    return rawData;
  }

  mapRawData(rawDataEntry: T, index: number, array: T[]): T {
    // keep empty; to be called by extended class
    return rawDataEntry;
  }

  private setFirstSorting(): void {
    this.firstSorting = this.getFirstSorting();
    this.changeDetectorRef.detectChanges();
  }

  private getFirstSorting(): MatSortable {
    if (this.customDefaultSort) return this.customDefaultSort;
    let localStorageString: string | null = null;
    if (this.localStorageSortKey && this.localStorageSortKey !== '') {
      localStorageString = localStorage.getItem(this.localStorageSortKey);
    }
    if (localStorageString) {
      const localStorageSorting = JSON.parse(localStorageString) as MatSortable;
      if (this.allColumns?.includes(localStorageSorting.id)) {
        return JSON.parse(localStorageString) as MatSortable;
      }
    }
    if (this.defaultSort) {
      return this.defaultSort;
    }
    if (this.allColumns && this.allColumns[0]) {
      return {
        id: this.allColumns[0],
        start: 'asc'
      } as MatSortable;
    } else {
      throw new Error(`Could not set first sorting: ${this.constructor.name}`);
    }
  }

  private setPagination(): void {
    if (!this.paginator) return;
    this.subscribeToPagination();
    this.setInitialPagination();
    if (this.inputData) {
      this.dataSource.paginator = this.paginator;
    }
  }

  private setInitialPagination(): void {
    if (!this.paginator) return;
    let localStorageString: string | null = null;
    if (this.localStoragePaginateKey && this.localStoragePaginateKey !== '') {
      localStorageString = localStorage.getItem(this.localStoragePaginateKey);
    }
    if (localStorageString) {
      const pagination = JSON.parse(localStorageString);
      this.paginator.pageSize = pagination.pageSize as number;
      this.paginator.pageIndex = pagination.pageIndex as number;
    } else {
      this.setDefaultPagination();
    }
  }

  private setDefaultPagination(): void {
    if (!this.paginator) return;
    this.paginator.firstPage();
    this.paginator.pageSize = this.pageSizeOptions[0];
  }

  private subscribeToPagination(): void {
    if (!this.localStoragePaginateKey || this.localStoragePaginateKey === '') return;
    this.paginate$ = this.paginator?.page.pipe().subscribe(() => {
      localStorage.setItem(
        this.localStoragePaginateKey as string,
        JSON.stringify({
          pageSize: this.paginator?.pageSize,
          pageIndex: this.paginator?.pageIndex,
        })
      );
    });
  }

  private setSorting(): void {
    this.sort$ = this.sort?.sortChange.pipe(skip(1)).subscribe(() => {
      this.handleSortChange();
    });
    if (!this.sort || !this.firstSorting) return;
    this.initSorting();
  }

  private initSorting(): void {
    if (!this.sort || !this.firstSorting) return;
    this.sort.sort(this.firstSorting);
    this.sort.active = this.firstSorting.id;
    this.sort.start = this.firstSorting.start;
    this.sort.direction = this.firstSorting.start;
    this.dataSource.sort = this.sort;
  }

  private handleSortChange(): void {
    this.paginator?.firstPage();
    if (!this.localStorageSortKey || this.localStorageSortKey === '') return;
    localStorage.setItem(
      this.localStorageSortKey as string,
      JSON.stringify({
        id: this.sort?.active,
        start: this.sort?.direction,
      })
    );
  }

  private subscribeToFetchFromApiTriggers(): void {
    if (!this.inputApiService || !this.inputApiService.items$ || !this.sort || !this.paginator) return;
    this.service$ = merge(
      this.sort.sortChange,
      this.paginator.page,
      this.formValues,
      this.triggerFilterSortPaginate$,
      this.inputApiService.triggerFilterSortPaginate$
    ).pipe(
      switchMap(() => {
        this.loadedFromServiceError = false;
        if (!this.inputApiService) throw new Error(`No inputService: ${this.constructor.name}`);
        if (!this.filterSortPaginatePostUrl) throw new Error(`No filterSortPaginatePostUrl: ${this.constructor.name}`);
        this.loadedFromService = false;
        const filtersSortPaginate = this.getFiltersSortPaginate();
        if (filtersSortPaginate) this.inputApiService.setFiltersSortPaginate(filtersSortPaginate);

        return this.inputApiService.getRequestForFiltersSortPaginatePost(this.filterSortPaginatePostUrl).pipe(
          catchError(() => {
            this.loadedFromServiceError = true;
            this.totalCountUpdated.emit(0);
            return of({items: [], totalCount: 0});
          })
        );
      }),
      map((response: { items: T[], totalCount: number }) => {
        this.loadedFromService = true;
        this.paginationTotalCount = response.totalCount;
        this.totalCountUpdated.emit(this.paginationTotalCount);
        if (!this.filterSortPaginateKeyForItems) throw new Error(`No filterSortPaginateKeyForItems passed: ${this.constructor.name}`);
        // @ts-ignore
        if (!response[this.filterSortPaginateKeyForItems]) throw new Error(`filterSortPaginateKeyForItems not found in FSP results: ${this.constructor.name}`);
        // @ts-ignore
        return response[this.filterSortPaginateKeyForItems] as T[];
      }),
    )
      .subscribe(data => {
      if (!data) return;
      if (this.getAndHandleTooLargePageIndex()) return;
      this.dataSource.data = this.getFilteredAndMappedRawData(data);
    });
  }

  private getAndHandleTooLargePageIndex(): boolean {
    if (!this.paginator) return false;
    const currentPageMinAmount = ((this.paginator.pageIndex + 1) * (this.paginator.pageSize)) - (this.paginator.pageSize - 1);
    if (currentPageMinAmount > this.paginationTotalCount) {
      this.paginator.firstPage();
      return true;
    } else {
      return false;
    }
  }

  private getFiltersSortPaginate(): FilterSortPaginate | void {
    if (!this.sort || !this.paginator) return;
    const filtersSortPaginate: FilterSortPaginate = {
      sortBy: this.sort.active,
      sortDirection: this.sort.direction,
      limit: this.paginator.pageSize,
      page: this.paginator.pageIndex + 1,
    };
    const formValues = this.formValues.getValue();
    if (formValues && Object.keys(formValues).length !== 0) {
      filtersSortPaginate.filters = this.formValues.getValue();
    }
    return Object.assign(
      new FilterSortPaginate(),
      filtersSortPaginate
    );
  }

  handleFilterFormChanges(formValues: ExperiTableFilters, manualInput = true): void {
    if (manualInput) {
      this.paginator?.firstPage();
    }
    if (this.inputData) {
      if (formValues?.query) {
        this.dataSource.filter = formValues.query.toLowerCase().trim();
      } else {
        this.dataSource.filter = '';
      }
    }
    if (this.inputApiService) {
      this.formValues.next(formValues);
    }
  }

  setDisplayColumns(displayedColumns?: string[]): void {
    if (!this.displayedColumns) {
      this.updateDisplayColumns(displayedColumns);
    }

    if (JSON.stringify(this.displayedColumns) !== JSON.stringify(displayedColumns)) {
      this.updateDisplayColumns(displayedColumns);
    }
  }

  private updateDisplayColumns(displayedColumns?: string[]): void {
    if (!displayedColumns) {
      console.warn('No columns');
      return;
    }
    this.displayedColumns = [...displayedColumns];
    if (this.sort && !this.displayedColumns.includes(this.sort.active)) {
      this.sort.sort(this.getFirstSorting());
    }
    this.tryInitDataSource();
  }

  private tryInitDataSource(): void {
    if (this.dataSourceSet) return;
    if (!this.displayedColumns) return;
    this.changeDetectorRef.detectChanges();
    this.initDataSource();
  }

  private initDataSource(): void {
    this.dataSourceSet = true;
    this.setPagination();
    this.setSorting();
    if (this.inputData) {
      this.paginationTotalCount = this.inputData.length;
      this.totalCountUpdated.emit(this.paginationTotalCount);
      this.dataSource.data = this.getFilteredAndMappedRawData(this.inputData);
      this.getAndHandleTooLargePageIndex();
    } else if (this.inputApiService) {
      this.subscribeToFetchFromApiTriggers();
    } else if (this.inputWSService) {
      this.subscribeToTriggerWSUpdateInputs();
    }
  }

  private subscribeToTriggerWSUpdateInputs(): void {
    if (!this.sort || !this.paginator) return;
    this.triggerWSUpdateInputs$ = merge(
      this.sort.sortChange,
      this.paginator.page,
      this.formValues,
      this.getWebSocketService().webSocketReconnected$,
    ).pipe().subscribe(() => {
      this.loadedFromService = false;
      const filterSortPaginate = this.getFiltersSortPaginate();
      if (filterSortPaginate) {
        this.inputWSService?.setFiltersSortPaginate(filterSortPaginate);
      }
    });
  }

  private getWebSocketService(): WebSocketService {
    if (this.customWebSocketService) {
      return this.customWebSocketService;
    } else {
      return this.webSocketService;
    }
  }

  onSelectModeChange(selectMode: boolean): void {
    if (selectMode === this.selectModeActive) return;
    this.changeDetectorRef.markForCheck();
    this.selectModeActive = selectMode;
    this.changeDetectorRef.detectChanges();
    if (this.selectModeActive) {
      this.showSelectModeColumn();
    } else {
      this.hideSelectModeColumn();
    }
  }

  private showSelectModeColumn(): void {
    if (!this.displayedColumns) return;
    const columnToSwapForSelectColumn = this.getColumnToSwapForSelectColumn();
    if (!columnToSwapForSelectColumn) return;
    this.columnToSwapForSelectColumn = columnToSwapForSelectColumn;
    const columnToSwapForSelectColumnIndex = this.displayedColumns.findIndex(col => col === this.columnToSwapForSelectColumn);
    if (columnToSwapForSelectColumnIndex < 0) return;
    this.displayedColumns[columnToSwapForSelectColumnIndex] = '_select';
  }

  private getColumnToSwapForSelectColumn(): string | void {
    if (!this.displayedColumns) return;
    let columnToSwap = 'actions';
    if (!this.displayedColumns?.includes('actions')) {
      columnToSwap = this.displayedColumns[this.displayedColumns.length - 1];
    }
    return columnToSwap;
  }

  private hideSelectModeColumn(): void {
    if (!this.displayedColumns) return;
    const columnToSwapForSelectColumnIndex = this.displayedColumns.findIndex(col => col === '_select');
    if (columnToSwapForSelectColumnIndex < 0) return;
    if (!this.columnToSwapForSelectColumn) return;
    this.displayedColumns[columnToSwapForSelectColumnIndex] = this.columnToSwapForSelectColumn;
    this.selection.clear();
  }

  private subscribeToSelection(): void {
    this.selection$ = this.selection.changed.subscribe(() => this.setIsAllSelected());
  }

  private setIsAllSelected(): void {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    this.isAllSelected = numSelected === numRows;
  }

  masterToggle(): void {
    if (this.isAllSelected) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  onTriggerFilterSortPaginate(): void {
    this.triggerFilterSortPaginate$.next();
  }

  ngOnDestroy(): void {
    this.sort$?.unsubscribe();
    this.paginate$?.unsubscribe();
    this.service$?.unsubscribe();
    this.triggerWSUpdateInputs$?.unsubscribe();
    this.selection$?.unsubscribe();
    this.inputWSService$?.unsubscribe();
    this.onDestroyEnd();
  }

  onDestroyEnd(): void {
    // keep empty; to be called by extended class
  }
}
