export class FiltersUsersTable {
  query?: string;
  isActive?: boolean;

  getTrimmedFilters(): FiltersUsersTable {
    const trimmedFilters: FiltersUsersTable = Object.assign(new FiltersUsersTable(), {});

    if (this.query && this.query !== '') {
      trimmedFilters['query'] = this.query;
    }

    if (this.isActive || this.isActive === false) {
      trimmedFilters['isActive'] = this.isActive;
    }


    if (Object.keys(trimmedFilters).length > 0) {
      return trimmedFilters;
    } else {
      return null;
    }
  }
}
