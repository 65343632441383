import {ProjectStructureBase} from "./project-structure-base.model";

export class ProjectOverViewTableItem extends ProjectStructureBase {
  _sortOrder: number;
  _level: number;
  _isCollapsed: boolean;
  _parentSectionId: string;
  _parentItemId?: string;
  _isGeneratedByDefault: boolean;
  _readOnly: boolean;
  _exactId?: string;
  projectItemType: number;
}
