<div *ngIf="environment.deprecatedEnvSuggestionUrl; else showApp"
     class="deprecated-wrapper">
  <img alt="EPC logo" src="../../assets/img/epc-logo.png" width="491" height="190"/>
  <h1>Let op!</h1>
  <p>
    Deze omgeving is niet meer in gebruik.
    <br />Vanaf 1 januari 2024 zal deze melding niet meer getoond worden.
    <br /><br />
    We raden je aan om je bladwijzers/bookmarks en geïnstalleerde PWA's te updaten naar: {{environment.deprecatedEnvSuggestionUrl}}
  </p>

  <a mat-raised-button
     color="primary"
     href="{{environment.deprecatedEnvSuggestionUrl}}">
    <mat-icon>launch</mat-icon>
    Open nieuwe omgeving
  </a>
</div>

<ng-template #showApp>
  <app-version-checker></app-version-checker>
  <router-outlet></router-outlet>
</ng-template>

