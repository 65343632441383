import {ProjectBase} from "./project-base.model";
import {Ticket} from "./ticket.model";
import {WorkOrder} from "./work-order.model";

export class ProjectStructureBase extends ProjectBase {
  ticketsCount?: number;
  tickets?: Ticket[];
  workOrdersCount?: number;
  workOrders?: WorkOrder[];

  materialCostsEstimate?: number;
  materialCostsSpent?: number;
  materialCostRemaining?: number;
  materialCostsSpentPercentage?: number;

  timeEstimate?: number;
  timeSpent?: number;
  timeRemaining?: number;
  timeSpentPercentage?: number;

  totalCostsEstimate?: number;
  totalCostsSpent?: number;
  totalCostsSpentPercentage?: number;

  perHourType?: {
    mechanicOutdoor?: ProjectStructureBase,
    mechanicIndoor?: ProjectStructureBase,
    performer?: ProjectStructureBase,
    projectLeader?: ProjectStructureBase,
    engineer?: ProjectStructureBase,
    softwareEngineer?: ProjectStructureBase,
  }
}
