import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ticketStripNumber'
})
export class TicketStripTicketNumber implements PipeTransform {

  transform(ticketNumber: string): string {
    if (!ticketNumber) {
      return null;
    }
    const splitTicketNumber = ticketNumber.split('TS-');
    if (splitTicketNumber.length === 2) {
      return splitTicketNumber[1];
    } else {
      return ticketNumber;
    }
  }
}
