import {Pipe, PipeTransform} from '@angular/core';
import {Mechanic} from '../../../core/models/mechanic.model';

@Pipe({
  name: 'findInMechanics'
})
export class FindInMechanicsPipe implements PipeTransform {

  transform(allMechanics: Mechanic[], query: string): Mechanic[] {
    if (!allMechanics || !query) {
      return null;
    }

    return allMechanics.filter(mechanic => {
      return FindInMechanicsPipe.findInMechanic(query, mechanic);
    })
  }
  static findInMechanic(query: string, mechanic: Mechanic) {
    const lowerCaseQuery = query.toLowerCase();
    if (mechanic.name?.toLocaleLowerCase().indexOf(lowerCaseQuery) > -1) {
      return true;
    }
    if (mechanic.name?.toLocaleLowerCase().indexOf(lowerCaseQuery) > -1) {
      return true;
    }
    return false;
  }
}
