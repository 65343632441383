import {Component, Inject, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TimeoutError} from "rxjs";
import {environment} from '@env/environment';

@Component({
  selector: 'app-api-error-dialog',
  templateUrl: './api-error-dialog.component.html',
  styleUrls: ['./api-error-dialog.component.scss']
})
export class ApiErrorDialogComponent implements OnInit {
  private response: HttpErrorResponse | TimeoutError;

  url: string;
  error: string;
  message: string;
  isTimeOutError = false;

  constructor(
    private dialogRef: MatDialogRef<ApiErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) response: HttpErrorResponse | TimeoutError
  ) {
    this.response = response;
  }

  ngOnInit(): void {
    if (this.response instanceof HttpErrorResponse) {
      this.error = JSON.stringify(this.response.error);
      this.message = JSON.stringify(this.response.message);
    }
    if (this.response instanceof TimeoutError) {
      this.isTimeOutError = true;
      this.message = `Api Server verzoek duurde langer dan ${environment.apiServerResponseTimeoutMs}ms`;
    }
  }

  onReloadClick(): void {
    location.reload();
  }

  close() {
    this.dialogRef.close();
  }
}
