<span matTooltip="Front-End">
  {{environment.version}}
</span>

<ng-container *ngIf="backEndVersionService.backEndVersion">
  &nbsp;/&nbsp;
  <span matTooltip="Back-End{{backEndVersionService.backEndDate ? (': ' + (backEndVersionService.backEndDate | date:appConfig.dateHourMinutesFormat)) : '' }}">
    {{backEndVersionService.backEndVersion}}
  </span>
</ng-container>

