import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackEndVersionService {
  backEndVersion: string;
  backEndDate: Date;

  constructor() {
  }
}
