import {CentsToEurosPipe} from "../pipes/cents-to-euros.pipe";
import {EurosToCentsPipe} from "../pipes/euros-to-cents.pipe";
import {MaterialPreset} from '@app/core/models/material-preset.model';

export class Material extends MaterialPreset {
  installationPartId?: string;
  installationPartName?: string;
  installationPartCode?: string;
  fromStock?: boolean;
  exactExpenseId?: string;
  materialPresetId?: string;
  materialPresetName?: string;
  createdDirectlyInProject?: boolean;
  projectItemId?: boolean;

  _amount?: number;
  amountAsCents: number;

  _totalSalesPriceEuros?: number;
  totalSalesPriceCents: number;

  _totalCostPriceEuros?: number;
  totalCostPriceCents: number;

  override convertCentsToEuros?() {
    this._amount = CentsToEurosPipe.getEurosFromCents(this.amountAsCents);
    this._totalSalesPriceEuros = CentsToEurosPipe.getEurosFromCents(this.totalSalesPriceCents);
    this._totalCostPriceEuros = CentsToEurosPipe.getEurosFromCents(this.totalCostPriceCents);
    super.convertCentsToEuros();
  }

  override convertEurosToCents?(deleteEuroVars = true) {
    this.amountAsCents = EurosToCentsPipe.getCentsFromEuros(this._amount);
    this.totalSalesPriceCents = EurosToCentsPipe.getCentsFromEuros(this._totalSalesPriceEuros);
    this.totalCostPriceCents = EurosToCentsPipe.getCentsFromEuros(this._totalCostPriceEuros);

    if (deleteEuroVars) {
      delete this._amount;
      delete this._totalSalesPriceEuros;
      delete this._totalCostPriceEuros;
    }

    super.convertEurosToCents(deleteEuroVars);
  }
}
