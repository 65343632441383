import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {UserService} from './core/services/user.service';
import {appConfig} from "@app/core";
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import {ModifierKeyPressedService} from "@app/core/services/modifier-key-pressed.service";
import {NgSelectConfig} from '@ng-select/ng-select';
import {OnPageVisibilityChange} from 'angular-page-visibility';
import { environment } from '@env/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  environment = environment;

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {
    if (event.key === 'Shift') this.modifierKeyPressedService.modifierKeyPressed$.next('SHIFT');
    if (event.key === 'Alt') this.modifierKeyPressedService.modifierKeyPressed$.next('ALT');
    if (event.key === 'Control') this.modifierKeyPressedService.modifierKeyPressed$.next('CTRL');
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event) {
    if (event.key === 'Shift') this.modifierKeyPressedService.modifierKeyPressed$.next(null);
    if (event.key === 'Alt') this.modifierKeyPressedService.modifierKeyPressed$.next(null);
    if (event.key === 'Control') this.modifierKeyPressedService.modifierKeyPressed$.next(null);
  }

  @HostListener('window:blur', ['$event'])
  onBlur() {
    this.modifierKeyPressedService.modifierKeyPressed$.next(undefined);
  }

  @OnPageVisibilityChange()
  onVisible(): void {
    this.modifierKeyPressedService.modifierKeyPressed$.next(undefined);
  }

  constructor(
    private userService: UserService,
    private modifierKeyPressedService: ModifierKeyPressedService,
    private ngSelectConfig: NgSelectConfig
  ) {
    this.ngSelectConfig.appendTo = 'body';
    moment.locale('nl');
  }

  ngOnInit(): void {
    if(!environment.deprecatedEnvSuggestionUrl) {
      this.setUserUsingLocalStorage();
    }
  }

  private setUserUsingLocalStorage() {
    localStorage.removeItem(appConfig.localStorageKeys.oldUser);
    const storage = localStorage.getItem(appConfig.localStorageKeys.user);
    if (!storage) return;
    const user = JSON.parse(CryptoJS.AES.decrypt(storage, appConfig.ba9123jj12klasd).toString(CryptoJS.enc.Utf8));
    this.userService.setUser(user);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      document.getElementById('loading-wrapper')?.remove();
    }, 50)
  }
}
