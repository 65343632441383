import {Pipe, PipeTransform} from '@angular/core';
import {CompanyType} from '../../../core/models/company-type.model';

@Pipe({
  name: 'companyTypeById'
})
export class CompanyTypeByIdPipe implements PipeTransform {

  transform(allCompanyTypes: CompanyType[], id: string): CompanyType {
    if (!allCompanyTypes) return null;
    return allCompanyTypes.find(companyType => companyType.id === id);
  }

}
