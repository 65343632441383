import {CentsToEurosPipe} from "@app/core/pipes/cents-to-euros.pipe";
import {EurosToCentsPipe} from "@app/core/pipes/euros-to-cents.pipe";
import {appConfig} from "@app/core/index";

export class Rate {
  id: string;
  name: string;
  description: string;

  _mechanicOutdoorSalePerHourEuros?: number;
  mechanicOutdoorSalePerHourCents?: number;
  _mechanicOutdoorSalePerKilometerEuros?: number;
  mechanicOutdoorSalePerKilometerCents?: number;

  _mechanicIndoorSalePerHourEuros?: number;
  mechanicIndoorSalePerHourCents?: number;
  _mechanicIndoorSalePerKilometerEuros?: number;
  mechanicIndoorSalePerKilometerCents?: number;

  _performerSalePerHourEuros?: number;
  performerSalePerHourCents?: number;
  _performerSalePerKilometerEuros?: number;
  performerSalePerKilometerCents?: number;

  _projectLeaderSalePerHourEuros?: number;
  projectLeaderSalePerHourCents?: number;
  _projectLeaderSalePerKilometerEuros?: number;
  projectLeaderSalePerKilometerCents?: number;

  _engineerSalePerHourEuros?: number;
  engineerSalePerHourCents?: number;
  _engineerSalePerKilometerEuros?: number;
  engineerSalePerKilometerCents?: number;

  _softwareEngineerSalePerHourEuros?: number;
  softwareEngineerSalePerHourCents?: number;
  _softwareEngineerSalePerKilometerEuros?: number;
  softwareEngineerSalePerKilometerCents?: number;

  convertCentsToEuros?() {
    appConfig.hourTypes.forEach(type => {
      this[`_${type.type}SalePerHourEuros`] = CentsToEurosPipe.getEurosFromCents(this[`${type.type}SalePerHourCents`]);
      this[`_${type.type}SalePerKilometerEuros`] = CentsToEurosPipe.getEurosFromCents(this[`${type.type}SalePerKilometerCents`]);
    });
  }

  convertEurosToCents?(deleteEuroVars = true) {
    appConfig.hourTypes.forEach(type => {
      this[`${type.type}SalePerHourCents`] = EurosToCentsPipe.getCentsFromEuros(this[`_${type.type}SalePerHourEuros`]);
      this[`${type.type}SalePerKilometerCents`] = EurosToCentsPipe.getCentsFromEuros(this[`_${type.type}SalePerKilometerEuros`]);
    });

    if (deleteEuroVars) {
      appConfig.hourTypes.forEach(type => {
        delete this[`_${type.type}SalePerHourEuros`];
        delete this[`_${type.type}SalePerKilometerEuros`];
      });
    }
  }
}
