import {Pipe, PipeTransform} from '@angular/core';
import {isNumeric} from "@app/shared/utils/is-numeric";

@Pipe({
  name: 'centsToEuros'
})
export class CentsToEurosPipe implements PipeTransform {

  transform(cents: number): number {
    return CentsToEurosPipe.getEurosFromCents(cents);
  }

  static getEurosFromCents(cents: number): number {
    if (isNumeric(cents)) {
      return cents / 100;
    } else {
      console.warn('Not a number', this);
      return 0;
    }
  }
}
