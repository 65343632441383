import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'findInNotifiers'
})
export class FindInNotifiersPipe implements PipeTransform {

  transform(allNotifiers: string[], query: string): string[] {
    if (!allNotifiers || !query) {
      return null;
    }

    return allNotifiers.filter(notifier => {
      return notifier.toLowerCase().indexOf(query.toLowerCase()) > -1;
    });
  }
}
