import {CentsToEurosPipe} from "@app/core/pipes/cents-to-euros.pipe";
import {EurosToCentsPipe} from "@app/core/pipes/euros-to-cents.pipe";

export class Surcharge {
  _factor: number;
  _noStartTime: boolean;
  _noEndTime: boolean;

  id: string;
  surchargeGroupId: string;
  factorAsCents: number;
  day: number;
  startTime: string;
  endTime: string;

  convertCentsToEuros?() {
    this._factor = CentsToEurosPipe.getEurosFromCents(this.factorAsCents);
  }

  convertEurosToCents?(deleteEuroVars = true) {
    this.factorAsCents = EurosToCentsPipe.getCentsFromEuros(this._factor);

    if (deleteEuroVars) {
      delete this._factor;
    }
  }
}

export enum SurchargeDayEnum {
  zondag,
  maandag,
  dinsdag,
  woensdag,
  donderdag,
  vrijdag,
  zaterdag,
  feestdag,
}
