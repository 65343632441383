export class FiltersInstallationPartsTable {
  query?: string;
  installationPartGroupId?: string;

  getTrimmedFilters(): FiltersInstallationPartsTable {
    const trimmedFilters: FiltersInstallationPartsTable = Object.assign(new FiltersInstallationPartsTable(), {});

    if (this.query && this.query !== '') {
      trimmedFilters['query'] = this.query;
    }

    if (this.installationPartGroupId && this.installationPartGroupId !== '') {
      trimmedFilters['installationPartGroupId'] = this.installationPartGroupId;
    }

    if (Object.keys(trimmedFilters).length > 0) {
      return trimmedFilters;
    } else {
      return null;
    }
  }
}
