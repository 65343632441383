import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

export type ModifierKeyPressed = 'SHIFT' | 'ALT' | 'CTRL' | undefined;

@Injectable({
  providedIn: 'root'
})
export class ModifierKeyPressedService {
  modifierKeyPressed$ = new BehaviorSubject<ModifierKeyPressed>(undefined);
}
