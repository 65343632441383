import {Injectable} from '@angular/core';
import {CanActivate, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../services/user.service';

@Injectable()
export class CanViewDownloadsPageGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.userService.getUser();
    if (user._rights.canViewDownloadsPage) {
      return true;
    } else {
      this.router.navigate(['/dashboard'], {
        replaceUrl: true
      });
      return false;
    }
  }
}
