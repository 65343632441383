import {WorkOrder} from './work-order.model';
import {Duration} from "./duration.model";
import {AppChartResult} from "./app-chart-result.model";
import {SensorGroup} from "./sensor-group.model";
import {TicketAddresseeWorkTypeCounts} from "./ticket-addressee-work-type-counts.model";
import {Project} from "./project.model";

export class TicketAddressee {
  id?: string;
  referenceId?: string;
  name?: string;
  city?: string;
  street?: string;
  zipcode?: string;
  defaultProject?: Project;
  isBlocked: boolean;
  isCustomer: boolean;
  isSupplier: boolean;
  isLocation:    boolean;

  // Location Specific
  companyTypeId?: string;
  workOrders?: WorkOrder[];
  ticketsCount?: number;
  workOrdersCount?: number;
  photosCount?: number;
  materialsCount?: number;
  durations?: Duration[];
  workTypeCounts?: TicketAddresseeWorkTypeCounts[];
  durationArrival?: number;
  durationFunctionalRepair?: number;
  durationPermanentRepair?: number;
  durationBlockageRoad?: number;
  durationBlockageWater?: number;
  installationPieChart?: AppChartResult[];
  sensorGroups?: SensorGroup[];
  sensorsCount?: number;
  emailsCount?: number;
  externalFilesCount?: number;
  latitude?: number;
  longitude?: number;
  mapPinColor?: 'rgba(201, 62, 62, 0.6)' // high
    | 'rgba(226, 230, 5, 0.6)' // med
    | 'rgba(13, 71, 161, 0.6)' // low

  // Customer Specific
  projectsCount?: number;
  locationManagement?: string;
  defaultRateId?: string;
  defaultRateName?: string;
  defaultRateDescription?: string;
  defaultSurchargeGroupId?: string;
  defaultSurchargeGroupName?: string;
  defaultSurchargeGroupDescription?: string;
  defaultProjectSectionsTemplateId?: string;
  defaultProjectSectionsTemplateName?: string;
  debtorNumber: string;
}
