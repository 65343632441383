import {Pipe, PipeTransform} from '@angular/core';
import {Mechanic} from '../../../core/models/mechanic.model';

@Pipe({
  name: 'mechanicById'
})
export class MechanicByIdPipe implements PipeTransform {

  transform(allMechanics: Mechanic[], id: string): Mechanic {
    if (!allMechanics) {
      return null;
    }

    return allMechanics.find(mechanic => `${mechanic.id}` === `${id}`);
  }
}
