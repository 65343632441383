export class WorkOrderStatus {
  _backgroundColorLightened: string;

  label: string;
  abbreviation: string;
  id: string;
  count?: number;
  enum: number;
  backgroundColor: string;
  textColor: string;
}

export enum WorkOrderStatusEnum {
  Open = 10, // Open
  Submitted = 50, // Ingediend
  Closed = 60 // Afgesloten
}
