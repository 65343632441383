export class TicketState {
  label: string;
  id: string;
  count: number;
  enum: number;
}

export enum TicketStateEnum {
  Active = 10,
  HoldEpc = 40,
  HoldPlanned = 41,
  HoldCustomer = 50,
  Done = 60,
  FailureTypeDoneWithoutRepairState = 61,
  Removed = 80
}
