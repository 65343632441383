import {EventEmitter, Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {JsonPipe} from '@angular/common';
import * as signalR from "@microsoft/signalr";
import {take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private environment = environment;
  webSocketAutoRetryPending = false;
  webSocket: signalR.HubConnection;
  webSocketError$: EventEmitter<string | null> = new EventEmitter<string | null>();
  webSocketNext$: EventEmitter<string> = new EventEmitter<string>();
  webSocketComplete$: EventEmitter<void> = new EventEmitter<void>();
  received$: EventEmitter<{ action: string[], data: any }> = new EventEmitter<{ action: string[]; data: any }>();
  webSocketConnectedCount = 0;
  webSocketConnected$: EventEmitter<void> = new EventEmitter<void>();
  webSocketReconnected$: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private jsonPipe: JsonPipe,
  ) {
  }

  connect(apiToken: string): void {
    if (this.webSocket) {
      this.webSocket.stop().finally(() => {
        this.webSocket = undefined;
        this.setUpNewWebSocketConnection(apiToken)
      });
    } else {
      this.setUpNewWebSocketConnection(apiToken);
    }
  }

  private setUpNewWebSocketConnection(apiToken: string) {
    this.webSocket = new signalR.HubConnectionBuilder().withUrl(
      environment.ws, {accessTokenFactory: () => apiToken, headers: {version: this.environment.version}}
    ).build();

    this.webSocket.on('receive', (message: { action: string, data?: any }) => {
      this.handleIncomingMessage(message);
    });

    this.startWebSocketConnection();

    this.webSocket.onclose(() => {
      this.webSocketComplete$.emit();
    });
  }

  private async startWebSocketConnection() {
    try {
      await this.webSocket.start();
      this.handleWebSocketConnectionSuccess();
    } catch (err) {
      this.handleError(err)
    }
  }

  private handleWebSocketConnectionSuccess() {
    this.webSocketAutoRetryPending = false;
    this.webSocketConnectedCount++;
    if (this.webSocketConnectedCount > 1) {
      this.webSocketReconnected$.emit();
      this.webSocketConnected$.emit();
    } else {
      this.webSocketConnected$.emit();
    }
  }

  private handleIncomingMessage(message: { action: string, data?: any }): void {
    this.webSocketNext$.emit();
    this.webSocketError$.emit(null);
    this.received$.emit({
      action: message.action.split('/'),
      data: message.data
    });
  }

  private handleError(event: Event | CloseEvent | Error): void {
    if (event instanceof Error) {
      this.webSocketError$.emit(`WS Error: ${this.jsonPipe.transform(event)})`);
    }
    if (event instanceof Event) {
      this.webSocketError$.emit('Unexpected WS-error');
    }
    if (event instanceof CloseEvent) {
      console.warn(event);
      this.webSocketError$.emit(`WS close event: ${this.jsonPipe.transform(event.code)})`);
    }
    this.webSocket?.stop();
  }

  sendMessage(action: string, data?: any) {
    if (!this.webSocket) return;
    if (this.webSocket.state !== 'Connected') {
      this.webSocketConnected$.pipe(take(1)).subscribe(() => {
        this.webSocket.invoke('send', {action, data});
      });
    } else {
      this.webSocket.invoke('send', {action, data});
    }
  }

  clear(): void {
    this.webSocketAutoRetryPending = false;
    this.webSocket?.stop().finally(() => {
      this.webSocket = undefined;
    });
  }
}
