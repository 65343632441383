import {Rate} from "./rate.model";
import {ProjectSectionsTemplate} from "./project-sections-template.model";
import {SurchargeGroup} from "./surcharge-group.model";
import {ProjectStructureBase} from "./project-structure-base.model";
import {applyMixins} from "../../../apply-mixins";
import {CentsToEurosPipe} from '@app/core/pipes/cents-to-euros.pipe';
import {EurosToCentsPipe} from '@app/core/pipes/euros-to-cents.pipe';

export class Project {
  disabled?: string;

  projectNumber?: string;
  projectType?: number;
  exactId?: string;
  referenceCodeExact?: string;
  ticketAddresseeIdReference?: string;
  isActive: boolean;
  estimatedStartDate?: Date;
  estimatedEndDate?: Date;
  noTravelTime?: boolean;
  noTravelCosts?: boolean;

  _fixedPrice?: number;
  fixedPriceAsCents?: number;
  _fixedPriceAdditionalPercentage?: number;
  fixedPriceAdditionalPercentageAsCents?: number;
  _fixedPriceTotal?: number;
  fixedPriceTotalEuroCents?: number;

  projectManagerUserId?: string;
  projectManagerUserName?: string;

  customerName?: string;
  customerId?: string;
  customerDefaultRateId?: string;
  customerDefaultRateName?: string;
  customerDefaultSurchargeGroupId?: string;
  customerDefaultSurchargeGroupName?: string;

  durationDaysEstimate?: number;
  durationDaysPassed?: number;
  durationDaysPassedPercentage?: number;

  firstWorkOrderPeriodStartDate?: Date;
  lastWorkOrderPeriodEndDate?: Date;

  // Delta's
  materialDeltaPrognosis?: number;
  timeDeltaPrognosis?: number;
  totalDeltaPrognosis?: number;

  // Rate
  rate?: Rate;
  rateId?: string;
  copiedFromRateTemplateId?: string;
  copiedFromRateTemplateName?: string;

  // Surcharge Group
  surchargeGroup?: SurchargeGroup;
  surchargeGroupId?: string;
  copiedFromSurchargeGroupTemplateId?: string;
  copiedFromSurchargeGroupTemplateName?: string;

  // Project Template
  copiedFromProjectSectionsTemplateId?: string;
  copiedFromProjectSectionsTemplateName?: string;

  convertCentsToEuros?() {
    this._fixedPrice = CentsToEurosPipe.getEurosFromCents(this.fixedPriceAsCents);
    this._fixedPriceAdditionalPercentage = CentsToEurosPipe.getEurosFromCents(this.fixedPriceAdditionalPercentageAsCents);
    this._fixedPriceTotal = CentsToEurosPipe.getEurosFromCents(this.fixedPriceTotalEuroCents);
  }

  convertEurosToCents?(deleteEuroVars = true) {
    this.fixedPriceAsCents = EurosToCentsPipe.getCentsFromEuros(this._fixedPrice);
    this.fixedPriceAdditionalPercentageAsCents = EurosToCentsPipe.getCentsFromEuros(this._fixedPriceAdditionalPercentage);
    this.fixedPriceTotalEuroCents = EurosToCentsPipe.getCentsFromEuros(this._fixedPriceTotal);

    if (deleteEuroVars) {
      delete this._fixedPrice;
      delete this._fixedPriceAdditionalPercentage;
      delete this._fixedPriceTotal;
    }
  }
}

// https://www.digitalocean.com/community/tutorials/typescript-mixins
export interface Project extends ProjectStructureBase, ProjectSectionsTemplate {
}

applyMixins(Project, [ProjectStructureBase, ProjectSectionsTemplate]);

export enum ProjectTypeEnum {
  'Vaste Prijs',
  Regie,
  Contract,
}
